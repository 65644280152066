import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const UnitedKingdomTable = ({ usersInfo, downloadFile, checkUser }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" className="group-info-table">
        <TableHead className="group-info-table-head">
          <TableRow>
            <TableCell>#</TableCell>
            {/* <TableCell>שם מלא</TableCell> */}
            <TableCell>שם המשרד המגיש/חברה</TableCell>
            <TableCell>שם סוכן</TableCell>
            <TableCell>כתובת בארץ</TableCell>
            <TableCell>טלפון</TableCell>
            <TableCell>אימייל</TableCell>
            <TableCell>מקצוע</TableCell>
            <TableCell>תמונה של הדַרכּוֹן</TableCell>
            <TableCell>תמונת דַרכּוֹן</TableCell>
            
            <TableCell>הושלם</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersInfo.map(({ 
            // firstName, lastName, 
            companyName, agentName, email, phone, addressInIsrael, profession, pictureOfPersonFileName, pictureOfPassportFileName,  checked, _id }, i) => {
            return (
              <TableRow key={_id}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{companyName}</TableCell>
                <TableCell>{agentName}</TableCell>
                {/* <TableCell>{`${firstName || ""} ${lastName || ""}`}</TableCell> */}
                <TableCell>{addressInIsrael}</TableCell>
                <TableCell>{phone}</TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>{profession}</TableCell>
                <TableCell>
                  <p className="link" onClick={() => downloadFile(pictureOfPassportFileName)}>
                    הורד צילום דַרכּוֹן
                  </p>
                </TableCell>
                <TableCell>
                  <p className="link" onClick={() => downloadFile(pictureOfPersonFileName)}>
                    הורד תמונה
                  </p>
                </TableCell>
                <TableCell>
                  <div className="custom-checkbox" onClick={() => checkUser(_id, checked, i)}>
                    {checked ? <div className="check">&#10004;</div> : ""}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UnitedKingdomTable;
