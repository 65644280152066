export const groupDestinations = [
  { value: "morocco", label: "מרוקו" },
  { value: "india", label: "הודו" },
  { value: "israel", label: "ישראל" },
  { value: "unitedkingdom", label: "אנגליה" }
];

export const getDestination = (destinations, place) => {
  const dest = destinations.find((e) => e.value === place);
  if (dest) return dest.label;
  return "מרוקו";
};
