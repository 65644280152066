import examplePassportPicture from "../../assets/images/passport_example.jpeg";
import correctPersonPicture from "../../assets/images/correct_passport_pic.jpeg";
import wrongPersonPicture from "../../assets/images/wrong_passport_pic.jpeg";

const messages = {
  pictureOfPassport: "בכדי להמנע מעיכובים, נא הקפידו להצטלם לפי הדוגמאות הנ״ל:<br /><span style='color:red'>צילום מלא, ללא פלאשים</span>",
  pictureOfPassportEn:
    "In order to avoid delays, please be sure to take a photo according to the above example:<br />Full photo, no flashes",
  pictureOfPerson:
    "בכדי להמנע מעיכובים, נא הקפידו להצטלם לפי הדוגמאות הנ״ל:<br /><span style='color:red'>תמונה-רקע קיר לבן, שיער אסוף, מצח גלוי, פנים ישרות, להסתכל למצלמה ולא לצלם מהדרכון</span>",
  pictureOfPersonEn:
    "In order to avoid delays, please be sure to take a picture according to the above examples:<br />White wall background, hair gathered, forehead visible, straight face, looking at the camera"
};

export const photoPopUpHtml = (name) => {
  return `
<div class="d-flex width100 ${name.startsWith("pictureOfPassport") ? "justify-center" : "justify-between"}
  ${name.startsWith("pictureOfPerson") && "group-form-columns-on-mobile"}
  ">
  <div class=${name.startsWith("pictureOfPassport") ? "group-form-picture-of-passport" : "group-form-wrong-image"}>
    <img src=${name.startsWith("pictureOfPassport") ? examplePassportPicture : wrongPersonPicture} alt="example picture" class=${
    name.startsWith("pictureOfPerson") ? "group-form-wrong-image-filter" : ""
  } />
  </div>
  ${
    !name.startsWith("pictureOfPassport")
      ? `<img src=${correctPersonPicture} alt="example person" class="group-form-picture-of-person" />`
      : ""
  }
</div>
<div class="mt-20">${messages[name]}</div>
`;
};

export const maritalStatus = [
  { label: "רווק/ה", value: "single" },
  { label: "נשוי/נשואה", value: "married" },
  { label: "גרוש/ה", value: "divorced" },
  { label: "אלמן/ה", value: "widowed" }
];
