import moment from "moment";
import { useEffect, useState } from "react";
import templeBackgroundPng from "../../assets/images/temple-background.png";
import indiaBackgroundJpg from "../../assets/images/india-debashis-rc-biswas-diwali-unsplash.jpg";
import israelBackgroundPng from "../../assets/images/background-tel-aviv.png";
import unitedKingdomBackgroundPng from '../../assets/images/background-united-kingdom.png'
import { groupDestinations, getDestination } from "../destination/destinationUtils";

const backgrounds = [
  { country: "morocco", bg: templeBackgroundPng },
  { country: "india", bg: indiaBackgroundJpg },
  { country: "israel", bg: israelBackgroundPng },
  {country: "unitedkingdom", bg: unitedKingdomBackgroundPng}
];

function Header({ dateOfTrip, organizationName, groupAgentName, destination, lang}) {
  const [background, setBackground] = useState("");

  useEffect(() => {
    const place = backgrounds.find((e) => e.country === destination);
    if (place) setBackground(place.bg);
    else setBackground(templeBackgroundPng);
  }, [background, destination]);

  const infoBoxes = destination !== "unitedkingdom" ? [
    { heading: "תאריך הטיול", content: dateOfTrip ? formatDateParam(dateOfTrip) : "" },
    { heading: "שם החברה", content: organizationName || "" },
    {
      heading: destination === "israel" ? "Destination" : "יעד",
      content: destination === "israel" ? "Israel" : getDestination(groupDestinations, destination)
    },
    { heading: "אחראי", content: groupAgentName || "" }
  ] 
  : [
    // { heading: "תאריך הטיול", content: dateOfTrip ? formatDateParam(dateOfTrip) : "" },
    { heading: "שם החברה", content: organizationName || "" },
    {
      heading: "יעד",
      content: getDestination(groupDestinations, destination),
    },
  ] 
  ;

  return (
    <div className="group-form-title-bar-container">
      <div className="header-background-img" style={{ backgroundImage: `url(${background})` }}>
        {destination && destination !== "morocco" && <div className="header-background-overlay" />}
      </div>
      {lang==='en' ? <h1 className="group-form-page-title">Fastest way to get a visa</h1> : 
      destination === 'unitedkingdom' ? <h1 className="group-form-page-title">הדרך המהירה להוצאת ויזה לאנגליה</h1> :
      <h1 className="group-form-page-title">הדרך המהירה להוצאת ויזה</h1>}
      <div className="group-form-info-box-container">
        {infoBoxes.map(({ heading, content }, i) => {
          if (content) {
            return (
              <div className="group-form-info-box p-0" key={heading}>
                <h3 className="group-form-info-box-header">{heading}</h3>
                <p className="group-form-info-box-content">{content}</p>
              </div>
            );
          } else { return null}
        })}
      </div>


    </div>
  );
}

export default Header;

const formatDateParam = (date) => moment(Number(date)).format("DD-MM-YYYY");



