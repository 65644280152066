import { useEffect, useState, useRef } from "react";
import adminServices from "../../../Services/adminService";
import { useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import config from "../../../config";
import "../../../assets/css/generalAdmin.css";
import MoroccoTable from "../../groupTables/MoroccoTable";
import IndiaTable from "../../groupTables/IndiaTable";
import IsraelTable from "../../groupTables/IsraelTable";
import { groupDestinations, getDestination } from "../../destination/destinationUtils";
import "../../../assets/css/groupInfoManagerTable.css";
import UnitedKingdomTable from "../../groupTables/UnitedKingdomTable";

function GroupInfo() {
  const { groupId } = useParams();
  const location = useLocation();
  const [groupInfo, setGroupInfo] = useState({});
  const [usersInfo, setUsersInfo] = useState([]);
  const [link, setLink] = useState("נדרש תאריך תחילת טיול");
  const [startDate, setStartDate] = useState("");
  const [clipboardCopyConfirmationOpen, setClipboardCopyConfirmationOpen] = useState(false);
  const updateDateRequestsRef = useRef(0);

  const headerHeadings = [
    {
      header: "יַעַד",
      text: getDestination(groupDestinations, groupInfo.destination) || groupInfo.destination
    },
    { header: "שם הארגון", text: groupInfo.organizationName }
  ];

  useEffect(() => {
    const initState = async () => {
      const groupInfo = await adminServices.getGroupById(groupId);
      const usersInfo = await adminServices.getGroupParticipants(groupId);
      if (groupInfo && usersInfo && !groupInfo.err && !usersInfo.err) {
        const startDate = convertDateToString(groupInfo.startDate);
        setGroupInfo({ ...groupInfo, startDate });
        setUsersInfo(usersInfo);
        if (startDate) {
          setStartDate(startDate);
          setLink(generateLink({ ...groupInfo, startDate }));
        }
        if (groupInfo.destination === "israel") {
          setLink(generateLinkIsrael(groupInfo._id));
        }
      }
    };

    initState();
  }, [groupId, location.state]);

  useEffect(() => {
    if (startDate !== "") {
      if (updateDateRequestsRef.current === 0 && startDate === groupInfo.startDate) {
        setLink(generateLink(groupInfo));
      } else {
        setLink("Processing Date");

        const timeoutId = setTimeout(async () => {
          if (dateIsValid(startDate)) {
            try {
              updateDateRequestsRef.current += 1;
              await adminServices.updateGroupById({ _id: groupId, startDate: new Date(startDate) });

              setLink(generateLink({ ...groupInfo, startDate }));
            } catch (err) {
              setLink("Failed To Update Date");
            }
          } else {
            setLink("Invalid Date");
          }
        }, 2000);

        return () => {
          clearTimeout(timeoutId);
        };
      }
    }
  }, [startDate, groupId, groupInfo]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setClipboardCopyConfirmationOpen(false);
  };

  const onDateChange = ({ target: { value } }) => {
    setStartDate(value);
  };

  const checkUser = async (userId, checked, i) => {
    const res = await adminServices.updateParticipantById(userId, { checked: !checked });
    if (res.success) {
      setUsersInfo((prevUserInfo) => {
        const currUserInfo = [...prevUserInfo];
        currUserInfo[i].checked = !checked;
        return currUserInfo;
      });
    }
  };

  const saveToClipBoard = () => {
    navigator.clipboard.writeText(link);
    setClipboardCopyConfirmationOpen(true);
  };

  const downloadFile = async (filename) => {
    const fileBase64String = await adminServices.agentDownloadUserFile(filename);
    if (!fileBase64String.err) {
      const blob = await b64toBlob(fileBase64String);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();
    }
  };

  return (
    <div className="mb-60">
      <div className="group-info-header">
        <span className="group-info-heading-container">
          <h3 className="group-info-header-subtitle">תאריך תחילת הטיול</h3>
          {groupInfo.destination === "israel" ? (
            "N/A"
          ) : (
            <input className="date-input" type="date" max="9999-12-31" value={startDate} onChange={onDateChange} />
          )}
        </span>

        {headerHeadings.map(({ header, text }, i) => (
          <span key={header} className={"group-info-heading-container " + (i === 0 ? "side-borders" : "")}>
            <h3 className="group-info-header-subtitle">{header}</h3>
            <h3 className="group-info-header-text">{text}</h3>
          </span>
        ))}
      </div>

      <div className="group-info-header mb-60 push" onClick={saveToClipBoard}>
        <span className="group-info-heading-container">
          <h3 className="group-info-header-subtitle">לינק של סוכן הקבוצות</h3>
          <h3 className="group-info-header-text ltr-layout link-break">{link}</h3>
        </span>
      </div>

      {groupInfo.destination === "india" ? (
        <IndiaTable usersInfo={usersInfo} downloadFile={downloadFile} checkUser={checkUser} />
      ) : groupInfo.destination === "israel" ? (
        <IsraelTable usersInfo={usersInfo} downloadFile={downloadFile} checkUser={checkUser} />
      ) : groupInfo.destination === "unitedkingdom" ? (
        <UnitedKingdomTable usersInfo={usersInfo} downloadFile={downloadFile} checkUser={checkUser} />
      ) : (
        <MoroccoTable usersInfo={usersInfo} downloadFile={downloadFile} checkUser={checkUser} />
      )}

      <Snackbar
        open={clipboardCopyConfirmationOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" variant="filled">
          <span style={{ margin: "1rem" }}>הקישור הועתק</span>
        </Alert>
      </Snackbar>
    </div>
  );
}

export default GroupInfo;

const dateIsValid = (inputDate) => {
  const date = moment(inputDate);
  const minDate = moment().startOf("D");
  const maxDate = moment("2100-12-31").endOf("D");

  if (!date._isValid || date < minDate || date > maxDate) {
    return false;
  }

  return true;
};

const generateLink = ({ organizationName, startDate, _id, agentFirstName, agentLastName, destination }) => {
  const dateAsNum = new Date(startDate).getTime();
  return encodeURI(
    `${config.websiteUrlEndpoint}group-form/${encodeURIComponent(organizationName)}/${encodeURIComponent(
      destination
    )}/${dateAsNum}/${_id}/${encodeURIComponent(agentFirstName || "" + " " + agentLastName || "")}`
  );
};

const generateLinkIsrael = (groupId) => {
  return encodeURI(`${config.websiteUrlEndpoint}group-form-israel/${groupId}`);
};

const convertDateToString = (date) => date && moment(date).format("YYYY-MM-DD");

const b64toBlob = async (base64, type = "application/octet-stream") => (await fetch(`data:${type};base64,${base64}`)).blob();
